import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Modal from "react-modal"
import Cookies from 'universal-cookie'
import ReactMarkdown from "react-markdown"
import moment from "moment"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Document from "../../components/document/document"
import { Section, Container } from "../../components/grid/grid"
import { HeadingBasic } from "../../components/heading/heading"
import { Cover } from "../../components/cover/cover"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"
import { Carousel } from "../../components/utils/render-functions"
import { WvcNewsSlider } from "../../components/glider/glider"
import { CoverMain } from "../../components/cover-main/cover-main"

const GET_PHONENUMBER = gql`
  query formWvc2023 {
    formWvc2023 {
      phoneNumber
      category
    }
  }
`

const Wvc2023Page = (location) =>{
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "wvc2023", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_CoreImageBlock {
              name
              attributes {
                url
                title
                className
                href
              }
            }
            ... on WordPress_CoreEmbedBlock {
              attributes {
                url
              }
            }
            ... on WordPress_CoreShortcodeBlock {
              name
              attributes {
                text
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption(format: RAW)
                  description(format: RAW)
                }
              }
            }
            ... on WordPress_CoreListBlock {
              name
              attributes {
                values
              }
            }
            ... on WordPress_CoreGroupBlock {
              name
              attributes {
                className
              }
              innerBlocks {
                ... on WordPress_CoreHeadingBlock {
                  name
                  attributes {
                    content
                    level
                    className
                  }
                }
                ... on WordPress_CoreParagraphBlock {
                  name
                  attributes {
                    ... on WordPress_CoreParagraphBlockAttributesV3 {
                      align
                      backgroundColor
                      content
                      className
                    }
                  }
                  originalContent
                }
                ... on WordPress_CoreImageBlock {
                  name
                  attributes {
                    url
                    title
                    className
                    href
                  }
                }
                ... on WordPress_CoreListBlock {
                  name
                  attributes {
                    values
                  }
                }
                ... on WordPress_CoreShortcodeBlock {
                  name
                  attributes {
                    text
                  }
                }
                ... on WordPress_CoreGroupBlock {
                  name
                  attributes {
                    className
                  }
                  innerBlocks {
                    ... on WordPress_CoreHeadingBlock {
                      name
                      attributes {
                        content
                        level
                      }
                    }
                    ... on WordPress_CoreParagraphBlock {
                      name
                      attributes {
                        ... on WordPress_CoreParagraphBlockAttributesV3 {
                          align
                          backgroundColor
                          content
                          className
                        }
                      }
                    }
                    ... on WordPress_CoreImageBlock {
                      name
                      attributes {
                        url
                        title
                        className
                      }
                    }
                    ... on WordPress_CoreListBlock {
                      name
                      attributes {
                        values
                      }
                    }
                  }
                }
              }
            }
          }
          lcDiagram {
            content {
              ... on WordPress_Page_Lcdiagram_Content_AddNew {
                title
                description
                list
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_AcfAcfgalleryBlock {
                name
                acf {
                  carousel_type
                  images {
                    title
                    mediaDetails {
                      file
                      height
                      width
                    }
                    slug
                    sourceUrl
                    acfAttachment {
                      location
                      expired
                    }
                    caption(format: RAW)
                    description(format: RAW)
                  }
                }
              }
              ... on WordPress_CoreEmbedBlock {
                attributes {
                  url
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
        wvcpdf: fileCategory(id: "wvc", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        allNews: posts(first: 1000, where: {language: EN, categoryName: "wvc-news", orderby: {field: DATE, order: DESC}}) {
          nodes {
            id
            slug
            title
            date
            excerpt
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
                slug
              }
            }
            translation(language: ID) {
              id
              slug
              title
              date
              excerpt(format:RAW)
              featuredImage {
                sourceUrl
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  const cookies = new Cookies()
  const [successModal, setSuccessModal] = useState(false)
  const [failedModal, setFailedModal] = useState(false)
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const hasRegistered = useRef(false)

  const currentUrl = process.env.BASE_URL + location.pathname
  const wordPress = staticData.wordPress?.page
  const seo = wordPress?.seo
  const lcDiagramList = wordPress?.lcDiagram?.content
  const pdfFiles = staticData.wordPress.wvcpdf?.filesPdf?.edges
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl
  const newsList = staticData?.wordPress?.allNews.nodes
  
  let pdfItems = []
  let wvcNewsList = []
  let coverItems = []

  pdfFiles?.length > 0 &&
  pdfFiles?.map(item => {
    pdfItems.push({
      title: item?.node?.title || "Title",
      linkTo:
        item?.node?.acfFile?.file !== null ?
        item?.node?.acfFile?.file?.mediaItemUrl : null,
      fileId:
        item?.node?.acfFile?.file != null && item?.node?.acfFile?.file?.databaseId,
      fileSize:
        item?.node?.acfFile?.file !== null && item?.node?.acfFile?.file?.filesize,
      date: `${moment(item?.node?.date).format("DD MMMM YYYY")} • ${moment(
        item.node.date
      ).format("HH:mm")}`,
    })
    return 1
  })

  newsList?.length > 0 && 
  newsList?.map(item => {
    wvcNewsList.push({
      image: item.featuredImage
        ? item.featuredImage.sourceUrl
        : "#",
      date: item.date,
      title: item.title,
      description: item.excerpt,
      link: `/news/${item.slug}`,
      category: item.categories.nodes[0].name,
      target: "_self",
    })
  })

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({
        url: item?.attributes?.url, 
        className: item?.attributes?.className
      })
    }
  })

  const handleCloseSuccessModal = () => {{
    setSuccessModal(false)
  }}

  const handleCloseFailedModal = () => {{
    window.location.href = "/wvc2023/register"
    setFailedModal(false)
  }}

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)
  
    return (
      <Form className="row pt-4" id="submissionModalForm">
        <div className="col-12 col-md-12 pb-3">
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={(val) =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Check"}
          </button>
        </div>
      </Form>
    )
  }

  const [formValues, setFormValues] = useState({
    phoneNumber: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ phoneNumber, }) {
      return {
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formWvc2023
      const timestamp = new Date().getTime()
      const expire = timestamp + (60 * 60 * 24 * 1000 * 1)
      const formatNumber = values.phoneNumber.split("+").join("")

      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) {
          console.log(">1")
          hasRegistered.current = true
          cookies.set('phoneNumber', formatNumber, { path: '/', expires: new Date(expire) })
          if (sessionStorage.getItem("phoneNumber")) {
            sessionStorage.removeItem("phoneNumber")
          }
          localStorage.setItem("selectedCategory", dataList[i].category)
          window.location.href = "/wvc2023/submission"
        }
      }
      if(hasRegistered.current = false){
        setFailedModal(true)
      }
    },
  })(LeadForm)

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({
          url: item?.attributes?.url, 
          className: item?.attributes?.className
        })
      }
    })
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let loginBtnSection = document.getElementById("desktopNav").querySelector(".nav-item.nav-desktop.ml-3")
    let loginBtnSectionMobile = document.getElementById("navbarMobile").querySelector(".navbar-toggle-wrapper.fixed-top .btn-login")
    let langSection = document.querySelector("#lang-wrapper")
    let coverImage = document.querySelector(".cover-full-top")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    loginBtnSection.classList.add('d-none')
    langSection.classList.remove('d-flex')
    langSection.classList.add('d-none')
    loginBtnSectionMobile.classList.add('d-none')

    if (coverImage) {
      coverImage.style.marginTop = "60px"
    }
    
    
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri} isWvc={true}>
      <SEO 
        title={`Writing and Video Competition (WVC) 2023`}
        fbTitle={`Writing and Video Competition (WVC) 2023`}
        twitterTitle={`Writing and Video Competition (WVC) 2023`}
        description={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        fbDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        twitterDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        url={currentUrl}
        img={featuredImage}
        fbImg={featuredImage}
        twitterImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
        <meta 
          name="keywords" 
          content={seo?.focuskw ? seo?.focuskw : ""}
        ></meta>
        <meta 
          name="copyright" 
          content="2023 | Sinar Mas Land"
        ></meta>
      </Helmet>
      <CoverMain
        h1={""}
        img={resizeScreen ? coverItems[1].url : coverItems[0]?.url}
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      />
      <Section>
        <Container>
          {wordPress?.blocks.map((item, i) => {
            switch (item.name) {
              case "core/heading":
                return (
                  <div key={`heading-${i}`} className="container container-md">
                    <div className={i === 0 ? "py-main pb-3 pt-4" : "p-0"}>
                      <HeadingBasic h2={item.attributes.content} />
                    </div>
                  </div>
                )
              case "core/paragraph":
                return (
                  <div
                    key={`headingbasic-${i}`}
                    className={`py-main pb-3 pt-3`}
                  >
                    <div className="container container-md">
                      {/* <HeadingBasic h2={wordPress?.title} /> */}
                      <HeadingBasic text={[item.attributes.content]} />
                    </div>
                  </div>
                )
              case "core/group":
                // if (item.attributes.className === "lc-diagram") {
                //   return(
                //     <div className="row">
                //       {item.innerBlocks.map((itemList, i) => {
                //         return(
                //         <div className="col-md-3" key={i}>
                //           {itemList.name === "core/heading" && 
                //           itemList.attributes.className === "title-diagram" && (
                //             <div className="col-md-12">
                //               <HeadingBasic h2={itemList.attributes.content} />
                //             </div>
                //           )}
                //           {itemList.name === "core/paragraph" && (
                //             <div className="col-md-12">
                //               <div className="container container-md">
                //                 <HeadingBasic text={[itemList.attributes.content]} />
                //               </div>
                //             </div>
                //           )}
                //           {itemList.name === "core/list" && (
                //             <div className="col-md-12">
                //               <ul>
                //                 <ReactMarkdown
                //                   source={itemList.attributes.values}
                //                   escapeHtml={false}
                //                 />
                //               </ul>
                //             </div>
                //           )}
                //         </div>
                //         )
                //       })}
                //     </div>
                //   )
                // } else 
                if (item.attributes.className === "kompetisi-group") {
                  return(
                    <div className="row">
                      {item.innerBlocks.map((itemList, i) => {
                        return(
                          <div className="col-md-6" key={i}>
                            {itemList.name === "core/heading" && 
                              itemList.attributes.className === "kompetisi" && (
                                <div className="col-md-12">
                                  <HeadingBasic h2={itemList.attributes.content} />
                                </div>
                              )}
                            {itemList.name === "core/heading" && 
                              itemList.attributes.className === "sub-kompetisi" && (
                                <div className="col-md-6">
                                  <HeadingBasic h2={itemList.attributes.content} />
                                </div>
                              )}
                            {itemList.name === "core/paragraph" && 
                              itemList.attributes.className === "detail-kompetisi" && (
                                <div className="col-md-6">
                                  <HeadingBasic text={[itemList.attributes.content]} />
                                </div>
                              )}
                          </div>
                        )
                      })}
                    </div>
                  )
                }
              case "core/image":
                return (
                  <div className="col-md-12">
                    <img
                      key={`image-${i}`}
                      alt="image"
                      src={item.attributes.url}
                      className={item.attributes.url ? "pb-main" : "d-none"}
                      width={item.attributes.url ? "100%" : "0"}
                    />
                  </div>
                )
              case "core/embed":
                return (
                  <div className="container py-main">
                    <Cover
                      variant="basic"
                      theme="dark"
                      img={null}
                      imgHeight="h-400px h-md-500px w-100"
                      className="cover cover-full-top"
                      videoSrc={item.attributes && item.attributes.url}
                      type={{ name: "core-embed/youtube" }}
                      audio={false}
                    />
                  </div>
                )
              case "core/shortcode":
                if(item.attributes.text === "wvc-pdf"){
                  return(
                    <Container className="container-md py-main pb-5 pt-5">
                      <div className="col-md-12">
                        <Document data={pdfItems} />
                      </div>
                    </Container>
                  )
                }else if(item.attributes.text === "lc-diagram"){
                  return(
                    <div className="row">
                      {lcDiagramList.map((element, i) => {
                        return(
                          <div className="col-md-3" key={i}>
                            <div className="col-md-12 text-center">
                              <h2>{element.title}</h2>
                            </div>
                            <div className="col-md-12 font-weight-bold">
                              {element.description}
                            </div>
                            <div className="col-md-12">
                              <ul>
                                <ReactMarkdown
                                  source={element.list}
                                  escapeHtml={false}
                                />
                              </ul>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                }else if(item.attributes.text === "kompetisi-wvc"){
                  <></>
                }else if(item.attributes.text === "wvc-news-list"){
                  return(
                    <div className="container pb-5 wvc-news">
                      <WvcNewsSlider id="wvc-news" data={wvcNewsList} />
                    </div>
                  )
                }
              case "acf/acfgallery":
                return (
                  <div key={`gallery-${i}`} className="pb-5">
                    <Carousel data={item} />
                  </div>
                ) 
              case "core/list":
                return (
                  <div className="container-md col-md-12" style={{wordWrap:"break-word"}}>
                    <ul>
                      <ReactMarkdown
                        source={item.attributes.values}
                        escapeHtml={false}
                      />
                    </ul>
                  </div>
                )        
              default:
                return <></>
            }
          })}
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        onRequestClose={handleCloseSuccessModal}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            <h2>Peringatan!</h2>
            <p className="m-0">
              Masukan nomor handphone Anda
            </p>
            <EnhancedLeadForm />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={failedModal}
        onRequestClose={handleCloseFailedModal}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            <h2>Anda Belum Terdaftar!</h2>
            <p className="m-0">
            Untuk mengikuti Sinar Mas Land Writing & Video Competition, silakan melakukan registrasi terlebih dahulu.
            </p>
            <button
              className="btn btn-ecatalog-theme mt-4"
              onClick={handleCloseFailedModal}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default Wvc2023Page