import React, { useState, useContext } from "react"
import { nanoid } from "nanoid"

import { LangContext } from "../../context/langContext"

import { HeadingBasic } from "../heading/heading"

import icPDF from "../../assets/img/icons/icPdfBlack.png"
import { replaceUnicode } from "../utils/utils"

const PDF = ({ title, size, link, fileId, type, date }) => {
  const { lang } = useContext(LangContext)

  const annualReportDataLayer = title => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "viewAnnualReport",
      event_category: "View Report",
      event_action: "View Annual Report",
      event_label: title || "Title",
    })
  }

  const sustainabilityDataLayer = title => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "viewSustainabilityReport",
      event_category: "View Report",
      event_action: "View Sustainability Report",
      event_label: title || "Title",
    })
  }

  return (
    <div className="docs-file-wrapper">
      <img alt="pdf" src={icPDF} />
      <div className="docs-file-content">
        <h5 className="docs-file-title">{title && replaceUnicode(title)}</h5>
        <div className="docs-file-info">
          <div className="docs-file-size">
            {/* {size < 1000 ? `${size}KB` : `${size / 1000}MB`} */}
            {date}
          </div>
          <div className="docs-btn-wrapper">
            <a
              className="btn btn-link fx-underline"
              href={`${process.env.URL}/download/index.php?id=${fileId}`}
              download
            >
              {lang === "EN" ? "Download" : "Unduh"}
            </a>
            <a
              href={
                link || "https://gahp.net/wp-content/uploads/2017/09/sample.pdf"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link fx-underline"
              onClick={e => {
                if (type === "sustainability") {
                  sustainabilityDataLayer(title)
                } else {
                  annualReportDataLayer(title)
                }
              }}
            >
              {lang === "EN" ? "View" : "Lihat"}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const PDFTemplate = ({ section, title, data, type }) => {
  const [id] = useState(nanoid)
  return (
    <div className="docs">
      <div className="container">
        {title && <HeadingBasic h2={title} />}
        {section ? <div className="docs-heading">{section}</div> : null}
        <div className="row row-2">
          {data &&
            data.map((item, i) => {
              return (
                <div key={`${id}-${i}`} className="col-md-6">
                  <PDF
                    title={data[i].title}
                    size={data[i].fileSize || 1.1}
                    link={data[i].linkTo}
                    fileId={data[i].fileId}
                    date={data[i].date || ""}
                    type={type}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default PDFTemplate
